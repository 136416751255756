import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import PauseIcon from '@material-ui/icons/Pause';
import WarningIcon from '@material-ui/icons/Warning';
import classnames from 'classnames';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { memo } from 'react';
import { fichajesProvider } from '../../api';
import { createTiempo } from '../../api/tareas-functions';
import { format, formatTiempo } from '../../utils';
import DeleteDialog from '../common/dialogs/DeleteDialog';
import { MarcajeTimePicker } from '../common/fields/KeyboardDatePicker';
import { EditFichajeButton } from './EditarFichaje';
import { EstadoRevisionChip } from './EstadoChip';
import FichajeEditMotivoRevisionTooltip from './FichajeEditMotivoRevisionTooltip';
import { TableCell, TableRow } from './TableComponents';

const useStyles = makeStyles(
    (theme) => ({
        noLaborable: {
            '& td:not($diferencia)': {
                color: theme.palette.neutral.primary,
            },
        },
        marcajeWrapperHeader: {
            display: 'flex',
            gap: theme.spacing(1),
            '& span': {
                width: 66,
            },
        },
        marcajeWrapper: {
            display: 'flex',
            gap: theme.spacing(1),
        },
        timePicker: {
            '& .MuiInputBase-input': {
                width: 36,
                fontSize: theme.typography.subtitle2.fontSize,
            },
            '& .MuiInputAdornment-positionEnd': {
                marginLeft: 0,
                display: 'none',
            },
        },
        addButton: {
            visibility: 'hidden',
            'tr:hover &': {
                visibility: 'visible',
            },
        },
        estadoChips: {
            display: 'flex',
            gap: `${theme.spacing(0.5)}px`,
        },
        icons: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
        iconWithTooltip: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 24,
            height: 24,
            borderRadius: '50%',
            '& svg': {
                fontSize: 18,
            },
        },
        pausaIcon: {
            backgroundColor: theme.palette.success.main,
            color: 'white',
            margin: theme.spacing(0.5),
        },
        efectivas: {
            display: 'flex',
            alignItems: 'center',
            gap: `${theme.spacing(0.5)}px`,
        },
    }),
    { name: 'RevisionFichaje' },
);

export const RevisionFichaje = memo(
    function RevisionFichaje({
        resumenFecha,
        maxMarcajes,
        onAddMarcaje,
        expanded,
        onUpdateMarcaje,
        onUpdateFichaje,
        onDeleteFichaje,
        onUpdateMotivo,
        diferenciaMin,
        diferenciaMax,
        bloqueado,
    }) {
        const classes = useStyles();

        const marcajes = resumenFecha.fichaje.marcajes;
        const numMarcajes = marcajes.length;
        const errors = [...new Set(resumenFecha.fichaje.errors)];
        const hasErrors = errors.length > 0;

        const diferenciaHasError =
            resumenFecha.laborables.diferencia < -diferenciaMin || resumenFecha.laborables.diferencia > diferenciaMax;

        const touched = marcajes.some((marcaje) => marcaje.hora_salida_touched || marcaje.hora_entrada_touched);
        const isComplete = marcajes.every((marcaje) => marcaje.hora_entrada && marcaje.hora_salida);

        let textoPausa = null;
        if (resumenFecha.fichaje.marcaje_pausa) {
            const marcajePausa = resumenFecha.fichaje.marcaje_pausa;

            textoPausa = marcajePausa.hora_salida
                ? `Descanso registrado de ${format(marcajePausa.hora_entrada, 'HH:mm')} a ${format(
                      marcajePausa.hora_salida,
                      'HH:mm',
                  )}`
                : `Descanso iniciado a las ${format(marcajePausa.hora_entrada, 'HH:mm')}`;
        }

        const estadoRevision = {
            color: '',
            label: '',
        };

        if (!resumenFecha.fichaje.operario_verificado) {
            estadoRevision.color = 'modificacion-solicitada';
            estadoRevision.label = 'Modificación solicitada';
        } else if (!resumenFecha.fichaje.admin_verificado) {
            estadoRevision.color = 'pendiente';
            estadoRevision.label = 'Pendiente aprobación';
        } else {
            estadoRevision.color = 'aprobado';
            estadoRevision.label = 'Aprobado';
        }

        const editable = !bloqueado;

        return (
            <TableRow className={resumenFecha.laborables.efectivas === 0 ? classes.noLaborable : null}>
                <TableCell className='fixed'>{format(resumenFecha.fecha, 'EEE dd')}</TableCell>

                {expanded && (
                    <>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.horario))
                                : 'No laborable'}
                        </TableCell>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.vacaciones))
                                : 'No laborable'}
                        </TableCell>
                        <TableCell className='fixed'>
                            {resumenFecha.laborables.horario !== null
                                ? formatTiempo(createTiempo(resumenFecha.laborables.ausencias))
                                : 'No laborable'}
                        </TableCell>
                    </>
                )}
                <TableCell className='fixed'>
                    {resumenFecha.laborables.horario !== null ? (
                        <div className={classes.efectivas}>
                            {formatTiempo(createTiempo(resumenFecha.laborables.efectivas))}
                            {resumenFecha.laborables.ausencias_no_retribuidas > 0 && (
                                <Tooltip
                                    arrow
                                    title={`Ausencias no retribuidas: ${formatTiempo(
                                        createTiempo(resumenFecha.laborables.ausencias_no_retribuidas),
                                    )}`}
                                >
                                    <WarningIcon fontSize='small' />
                                </Tooltip>
                            )}
                        </div>
                    ) : (
                        'No laborable'
                    )}
                </TableCell>
                {errors.length > 0 ? (
                    <>
                        <TableCell>
                            <Tooltip arrow title={errors.length > 0 ? errors.join('. \n') : ''}>
                                <div className='error'>
                                    <InfoIcon />
                                    Error
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell></TableCell>
                    </>
                ) : (
                    <>
                        <TableCell className='fixed'>
                            {formatTiempo(createTiempo(resumenFecha.laborables.fichadas))}
                        </TableCell>
                        <TableCell className={classnames('fixed', diferenciaHasError ? 'error' : null)}>
                            {formatTiempo(createTiempo(resumenFecha.laborables.diferencia, true))}
                        </TableCell>
                    </>
                )}

                {marcajes.map((marcaje, marcajeIdx) => (
                    <TableCell key={marcajeIdx} className='marcaje'>
                        <div className={classes.marcajeWrapper}>
                            <MarcajeTimePicker
                                inputVariant='outlined'
                                className={clsx(classes.timePicker, marcaje.hora_entrada_touched ? 'touched' : null)}
                                onChange={(date) => {
                                    onUpdateMarcaje(marcajeIdx, 'hora_entrada', date);
                                }}
                                forceDate={resumenFecha.fecha}
                                value={marcaje.hora_entrada}
                                hasError={marcaje.horaEntradaError || marcaje.hora_entrada === null}
                                disabled={!editable}
                            />
                            <MarcajeTimePicker
                                inputVariant='outlined'
                                className={clsx(classes.timePicker, marcaje.hora_salida_touched ? 'touched' : null)}
                                onChange={(date) => {
                                    onUpdateMarcaje(marcajeIdx, 'hora_salida', date);
                                }}
                                forceDate={resumenFecha.fecha}
                                value={marcaje.hora_salida}
                                hasError={marcaje.horaSalidaError || marcaje.hora_salida === null}
                                disabled={!editable}
                            />
                        </div>
                    </TableCell>
                ))}

                {new Array(maxMarcajes - numMarcajes + 1).fill(null).map((_, i) => (
                    <TableCell key={i} className={i === maxMarcajes - numMarcajes ? 'fill' : null}>
                        {i === 0 && editable && (
                            <IconButton
                                size='small'
                                className={classes.addButton}
                                onClick={() => {
                                    onAddMarcaje(numMarcajes + 1);
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        )}
                    </TableCell>
                ))}

                <TableCell>
                    <div className={classes.icons}>
                        {resumenFecha.fichaje.marcaje_pausa && (
                            <Tooltip arrow title={textoPausa}>
                                <div className={clsx(classes.iconWithTooltip, classes.pausaIcon)}>
                                    <PauseIcon />
                                </div>
                            </Tooltip>
                        )}
                        {(resumenFecha.fichaje.solicitud_revision || resumenFecha.fichaje.touched) && (
                            <FichajeEditMotivoRevisionTooltip fichaje={resumenFecha.fichaje} onUpdate={onUpdateMotivo}>
                                <IconButton className={classes.iconWithTooltip}>
                                    <WarningIcon />
                                </IconButton>
                            </FichajeEditMotivoRevisionTooltip>
                        )}
                    </div>
                </TableCell>

                <TableCell>
                    {resumenFecha.fichaje.id && (
                        <div className={classes.estadoChips}>
                            <EstadoRevisionChip
                                size='small'
                                label={estadoRevision.label}
                                className={estadoRevision.color}
                            />
                        </div>
                    )}
                </TableCell>
                {!editable && !bloqueado && <TableCell></TableCell>}
                {editable && (
                    <TableCell>
                        {resumenFecha.fichaje.operario_verificado &&
                            !resumenFecha.fichaje.admin_verificado &&
                            isComplete && (
                                <IconButton
                                    size='small'
                                    onClick={() =>
                                        fichajesProvider
                                            .revisar([resumenFecha.fichaje.id])
                                            .then((revisados) => onUpdateFichaje(revisados[0]))
                                    }
                                    disabled={touched || hasErrors}
                                >
                                    <DoneIcon />
                                </IconButton>
                            )}
                        <EditFichajeButton onSave={onUpdateFichaje} fichaje={resumenFecha.fichaje} />

                        {resumenFecha.fichaje.id && (
                            <DeleteDialog
                                text='¿Deseas borrar este fichaje?'
                                button={
                                    <Tooltip title='Borrar fichaje'>
                                        <IconButton aria-label='Borrar fichaje' size='small'>
                                            <DeleteIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                onConfirm={() => {
                                    fichajesProvider.delete(resumenFecha.fichaje.id).then(onDeleteFichaje);
                                }}
                            />
                        )}
                    </TableCell>
                )}
            </TableRow>
        );
    },
    (prevProps, nextProps) =>
        prevProps.resumenFecha.fichaje === nextProps.resumenFecha.fichaje &&
        prevProps.expanded === nextProps.expanded &&
        prevProps.diferenciaMin === nextProps.diferenciaMin &&
        prevProps.diferenciaMax === nextProps.diferenciaMax &&
        prevProps.maxMarcajes === nextProps.maxMarcajes,
);

RevisionFichaje.propTypes = {
    resumenFecha: PropTypes.any,
    maxMarcajes: PropTypes.any,
    operarioId: PropTypes.any,
    expanded: PropTypes.bool,
    onAddMarcaje: PropTypes.func,
    onUpdateMarcaje: PropTypes.func,
    onUpdateFichaje: PropTypes.func,
    onDeleteFichaje: PropTypes.func,
    onUpdateMotivo: PropTypes.func,
    diferenciaMin: PropTypes.any,
    diferenciaMax: PropTypes.any,
    bloqueado: PropTypes.bool,
};
