import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { solicitudesProvider } from '../../api';
import { dataProvider, solicitudEstadoLabels, solicitudEstadoSelectOptions } from '../../api/solicitudes';
import { useFetchData } from '../../hooks/useFetchData';
import { formatDate, formatDateTime, formatNumber, isAllDay } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import BaseListView, { numberOperatorFn, sumValuesAndFormatNumber, withTableState } from '../common/list/ListView';
import { NumberFilter } from '../common/list/NumberFilter';
import { SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { EditarAusenciaDialogButton } from './EditarAusencia';
import EstadoChip from './EstadoChip';

const ListView = withTableState('ausencias', BaseListView);

export default forwardRef(function AusenciasList(props, ref) {
    const fetchDataFn = useCallback(() => solicitudesProvider.getAllAusencias(), []);

    const { data, fetchData, setData } = useFetchData(fetchDataFn);

    const snackbar = useSnackbar();

    useEffect(() => {
        if (ref === null) return;

        ref.current = {
            refresh: fetchData,
        };
    }, [ref, fetchData]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onDelete = (idx) => {
        dataProvider
            .delete(idx)
            .then(() => {
                fetchData();
            })
            .catch((err) => snackbar.showMessage(err.message));
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Operario',
                accessor: 'operario',
            },
            {
                Header: 'Fecha solicitud',
                accessor: 'fecha',
                Cell: ({ value }) => formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Inicio',
                accessor: 'fecha_inicio',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Fin',
                accessor: 'fecha_fin',
                Cell: ({ value, row: { values } }) =>
                    isAllDay(values.fecha_inicio, values.fecha_fin) ? formatDate(value) : formatDateTime(value),
                Filter: DateColumnFilter,
                filter: dateFilterFn,
            },
            {
                Header: 'Dias',
                accessor: 'dias',
                Cell: ({ value }) => formatNumber(value),
                Filter: NumberFilter,
                filter: numberOperatorFn(),
                Footer: sumValuesAndFormatNumber,
            },
            {
                Header: 'Motivo',
                accessor: 'motivo',
            },
            {
                Header: 'Retribuida',
                id: 'retribuida',
                accessor: (row) => (row.retribuida ? 'Si' : 'No'),
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: [
                        {
                            value: 'Si',
                            label: 'Si',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ],
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
            },
            {
                Header: 'Estado',
                accessor: 'estado',
                // eslint-disable-next-line react/prop-types
                Cell: ({ value }) => <EstadoChip size='small' label={solicitudEstadoLabels[value]} className={value} />,
                Filter: SelectColumnFilter,
                filterOptions: {
                    options: solicitudEstadoSelectOptions,
                    includeAllOption: {
                        value: 'todos',
                        label: 'Todos',
                    },
                },
            },
        ],
        [],
    );

    const options = useMemo(
        () => ({
            useActions: true,
            deleteConfirmationText: 'borrar ausencia',
        }),
        [fetchData],
    );

    return (
        <ListView
            tableTitle='Listado de ausencias'
            basePath='/solicitudes'
            columns={columns}
            data={data}
            options={options}
            onDelete={onDelete}
            extraActions={(row) => (
                <>
                    <EditarAusenciaDialogButton
                        button={
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                        }
                        onSave={(updatedItem) => {
                            setData((data) =>
                                data.map((item) => (item.id === updatedItem.id ? { ...item, ...updatedItem } : item)),
                            );
                        }}
                        ausencia={row}
                    />
                </>
            )}
            style={{
                marginTop: 24,
            }}
        />
    );
});
