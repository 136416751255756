import isPast from 'date-fns/isPast';
import { useMemo } from 'react';
import { estadoPreparacionLabels, estadoPreparacionOptions } from '../../api/preparaciones_material';
import {
    estadoLabels,
    estadoTareaOptions,
    facturadoLabels,
    facturadoOptions,
    revisadoLabels,
    revisadoOptions,
    tipoLabels,
    tipoOptions,
} from '../../api/tareas';
import { createTiempo } from '../../api/tareas-functions';
import useAuthState from '../../AuthState';
import { formatDate, formatDateTime, formatTiempo, isAllDay } from '../../utils';
import { DateColumnFilter, dateFilterFn } from '../common/list/DateColumnFilter';
import { sumAndFormatTiempo } from '../common/list/ListView';
import { multipleSelectFilterFn, SelectColumnFilter } from '../common/list/SelectColumnFilter';
import { ClienteLinkCell } from '../dashboard/ClienteLink';
import MinutosRealesCell from './minutosRealesCell';
import PreparacionMaterialEstadoChip from './PreparacionMaterialEstadoChip';
import ServicioEstadoChip from './ServicioEstadoChip';
import ServicioFacturadoChip from './ServicioFacturadoChip';
import ServicioRevisadoChip from './ServicioRevisadoChip';
import useCategoriasTableColumn from './useCategoriasTableColumn';
import useOperariosTableColumn from './useOperariosTableColumn';

export default function useDefaultServiciosColumns(excludeColumns = []) {
    const {
        userInfo: {
            preferencias: {
                usar_referencias_tareas: usarReferencias,
                usar_preparacion_materiales: usarPreparacionMateriales,
            } = {},
        },
    } = useAuthState();
    const operariosColumn = useOperariosTableColumn();
    const categoriaColumn = useCategoriasTableColumn();

    return useMemo(() => {
        let columns = [];
        if (usarReferencias)
            columns.push({
                Header: 'Ref. interna',
                accessor: 'referencia_interna',
            });

        columns.push(
            ...[
                {
                    Header: 'Referencia',
                    id: 'numero',
                    accessor: (row) => (row.numero ? row.numero.toString().padStart(4, '0') : 'N/D'),
                },
                {
                    Header: 'Título',
                    accessor: 'descripcion',
                    noWrap: true,
                    // eslint-disable-next-line react/prop-types
                    Cell: ({ value }) =>
                        // eslint-disable-next-line react/prop-types
                        value.length > 40 ? <span title={value}>{value.substring(0, 30) + '...'}</span> : value,
                },
                {
                    Header: 'Cliente',
                    accessor: 'cliente',
                    Cell: ClienteLinkCell,
                    noWrap: true,
                },
                {
                    Header: 'Proyecto',
                    id: 'proyecto',
                    accessor: (row) => row.proyecto ?? 'Sin proyecto',
                    noWrap: true,
                },
                {
                    Header: 'Direccion (alias)',
                    accessor: 'direccion',
                    noWrap: true,
                },
                {
                    Header: 'Direccion (completa)',
                    accessor: 'direccion_compacta',
                    noWrap: true,
                },
                {
                    Header: 'Tipo',
                    accessor: 'tipo',
                    Cell: ({ value }) => tipoLabels[value],
                    Filter: SelectColumnFilter,
                    filter: multipleSelectFilterFn,
                    filterOptions: {
                        options: tipoOptions,
                        multiple: true,
                    },
                    exportValue: ({ value }) => tipoLabels[value],
                },
                categoriaColumn,
                {
                    Header: 'Estado servicio',
                    accessor: 'estado',
                    Cell: ServicioEstadoChip,
                    Filter: SelectColumnFilter,
                    filter: multipleSelectFilterFn,
                    filterOptions: {
                        options: estadoTareaOptions,
                        multiple: true,
                    },
                    exportValue: ({ value }) => estadoLabels[value],
                },
                {
                    Header: 'Urgente',
                    accessor: (row) => (row.urgente ? 'Si' : 'No'),
                },
                {
                    Header: 'Fecha creacion',
                    accessor: 'fecha_creacion',
                    Cell: ({ value }) => (value ? formatDateTime(new Date(value)) : 'Sin fecha'),
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                },
                {
                    Header: 'Inicio previsto',
                    accessor: 'fecha',
                    Cell: ({ value, row: { values } }) =>
                        value
                            ? isAllDay(values.fecha, values.fecha_fin)
                                ? formatDate(value)
                                : formatDateTime(value)
                            : 'Sin fecha',
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                    filterOptions: {
                        includeEmpty: true,
                    },
                },
                {
                    Header: 'Fin previsto',
                    accessor: 'fecha_fin',
                    Cell: ({ value, row: { values } }) =>
                        value
                            ? isAllDay(values.fecha, values.fecha_fin)
                                ? formatDate(value)
                                : formatDateTime(value)
                            : 'Sin fecha',
                    getExtraCellProps: ({ value }) => ({
                        style: { color: isPast(new Date(value)) ? 'red' : 'initial' },
                    }),
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                    filterOptions: {
                        includeEmpty: true,
                    },
                },
                {
                    Header: 'Inicio real',
                    accessor: 'fecha_inicio_real',
                    Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin registro'),
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                    filterOptions: {
                        includeEmpty: true,
                    },
                },
                {
                    Header: 'Fin real',
                    accessor: 'fecha_fin_real',
                    Cell: ({ value }) => (value ? formatDate(new Date(value)) : 'Sin registro'),
                    Filter: DateColumnFilter,
                    filter: dateFilterFn,
                    filterOptions: {
                        includeEmpty: true,
                    },
                },
                {
                    Header: 'Tiempo estimado',
                    accessor: 'minutos_estimados',
                    Cell: ({ value }) => (value > 0 ? formatTiempo(createTiempo(value)) : 'No estimado'),
                    Footer: sumAndFormatTiempo,
                },
                {
                    Header: 'Tiempo real',
                    accessor: 'minutos_reales',
                    Cell: MinutosRealesCell,
                    Footer: sumAndFormatTiempo,
                },
                operariosColumn,
                {
                    Header: 'Visible',
                    id: 'operarios-visible',
                    accessor: (row) => row.operarios.every((op) => !op.visible),
                    Cell: ({ value }) => (value ? 'Si' : 'No'),
                },
                {
                    Header: 'Notas internas',
                    accessor: 'notas_internas',
                },
                {
                    Header: 'Revisado',
                    accessor: 'revisado',
                    Cell: ServicioRevisadoChip,
                    Filter: SelectColumnFilter,
                    filter: 'equals',
                    filterOptions: {
                        options: revisadoOptions,
                        includeAllOption: {
                            value: 'todos',
                            label: 'Todos',
                        },
                    },
                    exportValue: ({ value }) => revisadoLabels[value],
                },
                {
                    Header: 'Facturado',
                    accessor: 'facturado',
                    Cell: ServicioFacturadoChip,
                    Filter: SelectColumnFilter,
                    filter: 'equals',
                    filterOptions: {
                        options: facturadoOptions,
                        includeAllOption: {
                            value: 'todos',
                            label: 'Todos',
                        },
                    },
                    exportValue: ({ value }) => facturadoLabels[value],
                },
                {
                    Header: 'Incidencias',
                    id: 'sin_resolver',
                    accessor: (row) => (row.sin_resolver ? 'Si' : 'No'),
                    Filter: SelectColumnFilter,
                    filterOptions: {
                        options: [
                            {
                                value: 'Si',
                                label: 'Si',
                            },
                            {
                                value: 'No',
                                label: 'No',
                            },
                        ],
                        includeAllOption: {
                            value: 'todos',
                            label: 'Todos',
                        },
                    },
                },
            ],
        );

        if (usarPreparacionMateriales)
            columns.push({
                Header: 'Estado preparacion',
                accessor: 'estado_preparacion',
                Cell: PreparacionMaterialEstadoChip,
                Filter: SelectColumnFilter,
                filter: multipleSelectFilterFn,
                filterOptions: {
                    options: estadoPreparacionOptions,
                    multiple: true,
                },
                exportValue: ({ value }) => estadoPreparacionLabels[value],
            });

        excludeColumns.forEach((accessorOrId) => {
            columns = columns.filter((col) => col.accessor !== accessorOrId && col.id !== accessorOrId);
        });

        return columns;
    }, [operariosColumn, categoriaColumn, usarPreparacionMateriales, usarReferencias, excludeColumns]);
}
